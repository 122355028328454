require('../scss/app.scss');
const $ = require('jquery');
require('bootstrap');
require('lazysizes');
require('slick-carousel');
require('sticky-table-headers');
require('./modernizr-custom');
const lightbox = require('../../node_modules/lightbox2/src/js/lightbox');
window.onscroll = function () {
    scrollFunction()
};

function scrollFunction() {
    if (document.body.scrollTop > 112 || document.documentElement.scrollTop > 112) {
        $('.navbar-brand').addClass('scrolled');
        $('.sons').addClass('scrolled');
    } else {
        $('.navbar-brand').removeClass('scrolled');
        $('.sons').removeClass('scrolled');
    }
}

lightbox.option({
    'albumLabel': "Bild %1 von %2",
});